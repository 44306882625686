import * as React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import PageEnhancer from '../../components/PageEnhancer';
import { RandomProfile, Anchor } from '../../components';
import { Header, PageLayout } from '../../components';
import { GridSection, GridBox, FullWidthElement } from '../../components/Grid';
import { Heading2, Text } from '../../helper/Typography';
import { Link } from 'gatsby';
import {
  getUrlFromId,
  filterArticlesAndCaseStudiesByType,
  toSlug,
} from '../../helper/helperServices';
import {
  sectionHeight,
  headlineSection,
  contentSection1,
  contentSection2,
} from './layouts';
import { LocalBackgroundImage, SeoDataComponent } from '@lws/react-components';
import { graphql } from 'gatsby';
import { SimpleImage, Button } from '../../helper/StyledHelper';
import Sprite from '../../components/Sprite/Sprite';
import { eggStates } from '../aboutUs/animations';
import { mapMetaObject } from '../../helper/helperServices';

interface EntryPageProps {
  location: any;
  data: {
    caseStudiesStageImage: File;
  };
  pageContext: {
    url: string;
    locale: string;
    node_locale: string;
    generalTexts: any;
    routeTexts: any;
    overviewTexts: any;
  };
}

const sortBySlug = (data: any, slugs: string[]) => {
  const images = data && data.images && data.images.nodes;
  const sortedImages = slugs.reduce((acc: {}, slug: string) => {
    acc[slug] = images.filter((image: any) =>
      image.relativeDirectory.includes(slug)
    );
    return acc;
  }, {});
  return sortedImages;
};

class CaseStudies extends PageEnhancer {
  caseStudies: any[];
  constructor(props: EntryPageProps) {
    super(props);
    this.caseStudies = filterArticlesAndCaseStudiesByType('caseStudy');
    this.state = {
      isScreenDesktop: true,
      egg: undefined,
      darkSections: [this.caseStudies[0].slug],
    };
  }

  componentDidMount() {
    this.callSuperComponentDidMountMethods();
    this.setInitialAnimationsState(['egg']);
  }

  setDarkThemeState = (darkTheme: boolean) => {
    this.setState({ darkTheme: darkTheme });
  };

  render() {
    const {
      generalTexts,
      overviewTexts,
      routeTexts: caseStudies,
      locale,
      node_locale,
    } = this.props.pageContext;
    const { pathname } = this.props.location;
    const data = this.props.data;
    const destinctSlugs = [
      ...new Set(this.caseStudies.map((e: any) => e.slug)),
    ];
    // sort images from graphQL response by the case study slug
    const sortedImages = sortBySlug(data, destinctSlugs);
    // get an asset from the sorted object according to it's name
    const getAssetByName = (slug: string, name: string) =>
      sortedImages[slug].filter((image: any) => {
        return image.base === name;
      })[0];

    return (
      <PageLayout
        userLanguage={locale}
        generalText={generalTexts}
        darkTheme={this.state.darkTheme}
      >
        <main className={this.state.darkTheme ? 'App Dark' : 'App Light'}>
          <Header
            pathname={pathname}
            darkTheme={this.state.darkTheme}
            generalTexts={generalTexts}
            lang={locale}
          />
          <SeoDataComponent data={mapMetaObject(caseStudies.meta).data} />
          <GridSection gridRows={sectionHeight.headlineSection}>
            <Anchor id={caseStudies.selectedCaseStudies} />
            <GridBox layout={headlineSection.headline}>
              <Text.pGrey>Case Studies</Text.pGrey>
              <Heading2>{caseStudies.selectedCaseStudies}</Heading2>
            </GridBox>
            {typeof this.state.egg !== 'undefined' && (
              <Sprite
                id={'eggStart'}
                layout={headlineSection.p1}
                name={'egg'}
                startId={'eggStart'}
                endId={'eggEnd'}
                animationState={this.state.egg}
                setAnimationState={this.setAnimationState}
                isSticky={false}
                isActive={true}
                originalAnimState={1}
                animationsDefinitions={eggStates}
              />
            )}
            <GridBox layout={headlineSection.p2}>
              <SimpleImage
                src={`/assets/caseStudies/overview/stage-pattern.svg`}
              />
            </GridBox>
          </GridSection>
          <GridSection gridRows={sectionHeight.bannerSection}>
            <FullWidthElement>
              <LocalBackgroundImage data={data.stageImage} />
            </FullWidthElement>
          </GridSection>
          {this.caseStudies
            .filter(e => e.locale === node_locale)
            .map((e, i) => (
              <div key={`${e.slug}-${i}`}>
                <Anchor id={e.slug} />
                <GridSection gridRows={sectionHeight.contentSection1}>
                  <GridBox layout={contentSection1.headline1}>
                    <Heading2 darkTheme={this.state.darkTheme}>
                      {`${i + 1}. `} {overviewTexts[e.slug].headline}
                    </Heading2>
                  </GridBox>
                  <GridBox layout={contentSection1.description}>
                    <Heading2 darkTheme={this.state.darkTheme}>
                      {overviewTexts[e.slug].description.headline}
                    </Heading2>
                    {overviewTexts[e.slug].description.paragraphs.map(
                      (p: any, i: number) => (
                        <Text.p
                          key={`${e.slug}-paragraph-${i}`}
                          darkTheme={this.state.darkTheme}
                        >
                          {p}
                        </Text.p>
                      )
                    )}

                    <Link
                      to={`${getUrlFromId(locale, 'caseStudies')}/${e.slug}/`}
                    >
                      <Button darkTheme={this.state.darkTheme}>
                        {caseStudies.readCaseStudy}
                      </Button>
                    </Link>
                  </GridBox>
                  <GridBox layout={contentSection1.image1}>
                    <LocalBackgroundImage
                      data={getAssetByName(e.slug, 'image-01.jpg')}
                    />
                  </GridBox>
                  <GridBox
                    layout={contentSection1.p1}
                    translateTop={[0.5, 0.5, 0, 0]}
                  >
                    <SimpleImage
                      src={`/assets/caseStudies/overview/${e.slug}/pattern-01.svg`}
                    />
                  </GridBox>
                </GridSection>
                <GridSection
                  gridRows={sectionHeight.contentSection2}
                  translateTop={[0, 0.5, 0.5, 0]}
                >
                  <GridBox layout={contentSection2.image2}>
                    <LocalBackgroundImage
                      data={getAssetByName(e.slug, 'image-02.jpg')}
                    />
                  </GridBox>
                  <GridBox layout={contentSection2.image3}>
                    <LocalBackgroundImage
                      data={getAssetByName(e.slug, 'image-03.jpg')}
                    />
                  </GridBox>
                  <GridBox
                    layout={contentSection2.p2}
                    translateTop={[0.5, 0.5, 0, 0]}
                  >
                    <SimpleImage
                      src={`/assets/caseStudies/overview/${e.slug}/pattern-02.svg`}
                    />
                  </GridBox>
                  <GridBox layout={contentSection2.p3} translateTop={0.5}>
                    <SimpleImage
                      src={`/assets/caseStudies/overview/${e.slug}/pattern-03.svg`}
                      className={'showAbove-mobile3'}
                    />
                  </GridBox>
                  <GridBox
                    layout={contentSection2.p4}
                    translateTop={[0.5, 0.5, 0, 0]}
                    className="showAbove-mobile3"
                  >
                    <SimpleImage
                      src={`/assets/caseStudies/overview/${e.slug}/pattern-04.svg`}
                    />
                  </GridBox>
                </GridSection>
              </div>
            ))}
          <VisibilitySensor
            partialVisibility={true}
            minTopValue={150}
            onChange={isVisible => this.setState({ darkTheme: isVisible })}
          >
            <RandomProfile
              userLanguage={locale}
              darkTheme={this.state.darkTheme}
            />
          </VisibilitySensor>
        </main>
      </PageLayout>
    );
  }
}

export default CaseStudies;

// prettier-ignore
export const caseStudiesStageImage = graphql`
         query caseStudiesAssets {
           stageImage: file(
             relativePath: { eq: "assets/caseStudies/overview/stage-image.jpg" }
           ) {
             ...LocalBackgroundImageFragment
           }
           images: allFile(
             filter: {
               extension: { regex: "/(jpeg|jpg)/" }
               relativeDirectory: {
                 regex: "/assets/caseStudies/overview/?(?:[^/]+/?)*$/"
               }
             }
           ) {
             nodes {
               relativeDirectory
               base
               ...LocalBackgroundImageFragment
             }
           }
         }
       `;
