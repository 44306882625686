// BPS:
// 2 - mobile1: 0 - 320
// 2 - mobile2: 321 - 479
// 3 - mobile3: 480 - 767
// 4 - tablet1: 768 - 1023
// 5 - tablet2: 1024 - 1279
// 6 - desktop1: 1280 - 1599
// 7 - desktop2: 1600 - 1919
// 8 - desktop3: 1920 - 2239
// 9 - desktop4: 2240 - x




export const sectionHeight = {
  headlineSection: [4, 4, 4, 4, 4, 3, 3, 3, 3],
  bannerSection: [6, 6, 6, 4, 4, 4, 4, 4, 4],
  contentSection1: [10, 9, 9, 9, 9, 6, 6, 6, 6],
  contentSection2: [6, 5, 6, 7, 7, 4, 4, 4, 4]
};

export const headlineSection = {
  headline: {
    top: [3, 4, 4, 2, 3, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 1, 2, 3, 4],
    boxWidth: [3, 3, 3, 3, 3, 3, 3, 3, 3],
  },
  p1: {
    top: [1, 1, 1, 1, 1, 1, 1, 1, 1],
    left: [2, 2, 2, 5, 5, 5, 6, 7, 8]
  },
  p2: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [3, 3, 3, 6, 6, 6, 7, 8, 9]
  }
};

export const contentSection1 = {
  headline1: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth: [3, 3, 3, 4, 4, 4, 4, 4, 4],
  },
  description: {
    top: [7, 7, 7, 7, 7, 3, 3, 3, 3],
    left: [1, 1, 1, 2, 2, 5, 6, 7, 8],
    boxWidth: [3, 3, 4, 4, 6, 3, 3, 3, 3],
  },
  image1: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left:       [1, 1, 1, 1, 1, 1, 2, 3, 4],
    boxWidth:   [3, 3, 4, 6, 8, 3, 3, 3, 3],
    boxHeight:  [3, 3, 3, 3, 3, 3, 3, 3, 3]
  },
  p1: {
    top: [5, 5, 5, 5, 5, 5, 5, 5, 5],
    left: [3, 3, 4, 5, 7, 3, 4, 5, 6]
  },
}

export const contentSection2 = {
  image2: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        [2, 2, 2, 2, 2, 2, 2, 2, 2],
    left:       [1, 1, 1, 2, 2, 2, 3, 4, 5],
    boxWidth:   [3, 3, 3, 3, 4, 3, 3, 3, 3],
    boxHeight:  [2, 2, 2, 2, 2, 2, 2, 2, 2]
  },
  image3: {
    //bps:      [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:        [4, 4, 4, 4, 4, 1, 1, 1, 1],
    left:       [2, 2, 2, 3, 4, 5, 6, 7, 8],
    boxWidth:   [2, 2, 3, 3, 4, 3, 3, 3, 3],
    boxHeight:  [3, 2, 3, 3, 3, 3, 3, 3, 3]
  },
  p2: {
    //bps:  [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:    [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left:   [1, 1, 1, 1, 1, 1, 2, 3, 4]
  },
  p3: {
    //bps:  [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:    [3, 3, 3, 3, 3, 3, 3, 3, 3],
    left:   [2, 2, 2, 2, 2, 2, 3, 4, 5]
  },
  p4: {
    //bps:  [1, 2, 3, 4, 5, 6, 7, 8, 9]
    top:    [6, 6, 6, 5, 5, 2, 2, 2, 2],
    left:   [3, 3, 4, 6, 8, 8, 9, 10, 11]
  },
}
